import React from 'react'

function Appsectioncta() {
  return (
    <div>
        {/* ======= Cta Section ======= */}
         <section id="cta" className="cta">
          <div className="container">
            <div className="row" data-aos="zoom-out">
              <div className="col-lg-9 text-center text-lg-start">
                <h3>Quer Saber Mais? Entre em Contato e Juntos Construiremos o Futuro!</h3>
                <p> Tem perguntas ou quer saber mais sobre nossa escola? Estamos aqui para ajudar! Entre em contato e descubra como podemos apoiar o desenvolvimento dos seus filhos e construir um futuro brilhante juntos. Clique no botão para começar a conversa – estamos ansiosos para ouvir de você!.</p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a className="cta-btn align-middle" href="# ">Chamada para ação</a>
              </div>
            </div>
          </div>
        </section>{/* End Cta Section */}
    </div>
  )
}

export default Appsectioncta