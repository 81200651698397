import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'

function Appprecarios() {
  return (
    <div>
      <Appheaderpage/>
      <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Estrutura de Preços</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Preçarios</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Nossos Preçarios</h2>
              <p>Nossos preços competitivos</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Bem-vindo à nossa Tabela de Preços! Aqui, você encontrará todas as informações detalhadas sobre os custos associados aos nossos serviços e produtos educacionais. A tabela está organizada para oferecer uma visão clara e acessível dos valores referentes a matrículas, mensalidades, materiais didáticos e outras taxas. <br />
                </p>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
              <h3>Cursos</h3>
              <ul >
                <li><strong><i className="ri-check-double-line" /></strong> Enfermagem </li>
                <li><strong><i className="ri-check-double-line" /></strong>  Análises Clínicas</li>
              </ul>
              <h3>Documentos Necessários</h3>
                <ul>
                  <li><i className="ri-check-double-line" /> Cópias do Bilhete de Identidade</li>
                  <li><i className="ri-check-double-line" /> Fotografias tipo passe</li>
                  <li><i className="ri-check-double-line" /> Atestado Médico</li>
                  <li><i className="ri-check-double-line" /> Cartão de Vacina</li>
                </ul>
            </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                Estamos felizes em apresentar nossos cursos de Ensino Médio com preços fixos que oferecem excelente custo-benefício. Veja por que nossa instituição é a melhor escolha para você:  
                </p>
                <div className="portfolio-description">
                <br />
                <br />
                <h2>Curso de Enfermagem </h2>
                <strong>Bem-vindo ao Curso de Enfermagem </strong>
                <br/>
                <p>
                Você está pronto para fazer a diferença na vida das pessoas e construir uma carreira sólida e gratificante? <strong>No Bendizer</strong>, oferecemos cursos de enfermagem que combinam excelência acadêmica com prática clínica de ponta para prepará-lo para um futuro brilhante na área da saúde.
                </p>
                <br/>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/anal_cli02.png" className="img-fluid" alt='Enfermagem' />
                </div>
                <br />
                <h3>Tabela de Preçarios</h3>
                <h2>Mensalidade</h2>
                <ul>
                  <li><strong>10ª classe</strong> -  23.500kz</li>
                  <li><strong>11ª classe</strong> -  24.800kz</li>
                  <li><strong>12ª classe</strong> -  24.800kz</li>
                  <li><strong>13ª classe</strong> -  34.500kz</li>
                </ul>
                <h2>Matricula</h2>
                <ul>
                  <li><strong>Matricula</strong> -  7.000kz</li>
                  <li><strong>Tunica</strong> -  20.200kz</li>
                  <li><strong>Cartão de Propina</strong> - 850kz</li>
                  <li><strong>Capa de processo</strong> - 350kz</li>
                </ul>
                <h2>Confirmação</h2>
                <ul>
                  <li><strong>Confirmação</strong> - 10.700kz </li>
                  <li><strong>Tunica</strong> -  20.200kz</li>
                  <li><strong>Cartão de Propina</strong> - 850kz</li>
                  <li><strong>Capa de processo</strong> - 350kz</li>
                </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="portfolio-description">
              <h2>Curso de Análises Clínicas</h2>
              <p>
              Se você tem interesse em compreender o funcionamento do corpo humano e em desempenhar um papel essencial na área da saúde, o curso de Análises Clínicas do <strong>Bendizer</strong> é a escolha perfeita para você.<br /> Aqui, oferecemos uma formação completa e atualizada que prepara os alunos para atuar com excelência no diagnóstico e monitoramento de condições de saúde.<br />
              </p>
              <div style={{borderRadius:'22px'}} className="portfolio-info">
              <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/anal_cli05.png" className="img-fluid" alt='analises clinicas' />
                </div>
                <br/>
                <h3>Tabela de Preçarios</h3>
                <h2>Mensalidade</h2>
                <ul>
                  <li><strong>10ª classe</strong> -  23.500kz</li>
                  <li><strong>11ª classe</strong> -  24.800kz</li>
                  <li><strong>12ª classe</strong> -  24.800kz</li>
                  <li><strong>13ª classe</strong> -  34.500kz</li>
                </ul>
                <h2>Matricula</h2>
                <ul>
                  <li><strong>Matricula</strong> -  7.000kz</li>
                  <li><strong>Tunica</strong> -  20.200kz</li>
                  <li><strong>Cartão de Propina</strong> - 850kz</li>
                  <li><strong>Capa de processo</strong> - 350kz</li>
                </ul>
                <h2>Confirmação</h2>
                <ul>
                  <li><strong>Confirmação</strong> - 10.700kz </li>
                  <li><strong>Tunica</strong> -  20.200kz</li>
                  <li><strong>Cartão de Propina</strong> - 850kz</li>
                  <li><strong>Capa de processo</strong> - 350kz</li>
                </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter />
    </div>
  )
}

export default Appprecarios