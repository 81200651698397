import React from 'react'

function Appsectionfaq() {
  return (
    <div>
         {/* ======= F.A.Q Section ======= */}
        <section id="faq" className="faq">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>F.A.Q</h2>
              <p>Perguntas frequentes</p>
            </div>
            <ul className="faq-list">
              <li>
                <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">Quais são os programas acadêmicos oferecidos? <i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  º Cursos mais desafiadores que oferecem uma carga acadêmica mais rigorosa, preparando os alunos para desafios universitários.<br /> º Cursos que seguem um currículo padrão nacional e permitem que os alunos obtenham créditos universitários.
                  </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Como é o suporte ao aluno além da sala de aula <i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  º Conselheiros que ajudam os alunos a planejar seus cursos, explorar opções de carreira e preparar-se para a faculdade ou mercado de trabalho.<br /> º Programas de tutoria para ajudar os alunos com dificuldades em disciplinas específicas, oferecidos por professores ou estudantes mais avançados.<br /> º Psicólogos escolares ou conselheiros que oferecem suporte emocional, aconselhamento individual e em grupo, para lidar com questões pessoais e acadêmicas.<br/> º niciativas para criar um ambiente seguro e respeitoso, incluindo políticas anti-bullying, programas de resolução de conflitos e apoio a vítimas de bullying.<br/> º Acesso a tecnologia moderna, como computadores, internet e softwares educacionais, além de treinamento em habilidades digitais.
                  </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Quais são as opções de atividades extracurriculares? <i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  º Clubes voltados para disciplinas específicas como matemática, ciências, literatura, história, entre outros. <br/> º Teatro, dança, música (banda, coral, orquestra), artes visuais (pintura, escultura, desenho), cinema e fotografia.<br/> º Times esportivos competitivos e recreativos, como futebol, basquete, vôlei, natação, atletismo, tênis, entre outros.<br/> º Oportunidades para desenvolver habilidades de argumentação, debate e falar em público.<br/>º Grupos que promovem a compreensão e celebração da diversidade cultural, étnica e religiosa
                  </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Qual é a política de segurança da escola? <i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    º Descrição de protocolos para situações de emergência, como incêndios, terremotos, intrusões e outras ameaças à segurança.<br/> º seguranças físicas para garantir a segurança dentro da instituição, incluindo controle de acesso, vigilância por câmeras, iluminação adequada e segurança nos portões.<br/> º Colaboração com a polícia local, bombeiros e outros serviços de emergência para planejamento e resposta a incidentes.<br/> 
                  </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">Como são as instalações e recursos da escola?<i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                  <p>
                  º Salas de aula modernas e equipadas, laboratórios de ciências, laboratórios de informática e outras instalações acadêmicas especializadas.<br/> º biblioteca da escola, incluindo coleções de livros impressos e digitais, acesso a bases de dados acadêmicas e recursos para pesquisa.<br/> º  Infraestrutura de tecnologia da escola, incluindo acesso à internet de alta velocidade, equipamentos audiovisuais, e dispositivos tecnológicos disponíveis para os alunos.<br/> º áreas esportivas como quadras esportivas, ginásios, piscinas, e outros espaços para atividades físicas e esportivas.<br/> º áreas comuns para os alunos socializarem, como cafeterias, áreas de recreação ao ar livre, e espaços para eventos sociais e culturais.
                  </p>
                </div>
              </li>
              <li>
                <div data-bs-toggle="collapse" href="#faq6" className="collapsed question">Quais são as oportunidades de preparação para a faculdade e carreira? <i className="bi bi-chevron-down icon-show" /><i className="bi bi-chevron-up icon-close" /></div>
                <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                  <p>
                    º Temos realizado eventos organizados para que os alunos conheçam representantes de universidades, empresas e instituições de ensino superior, possibilitando networking e obtenção de informações sobre programas acadêmicos e oportunidades de carreira.<br/>º Ofertas acadêmicas que incluem cursos preparatórios para exames de admissão, redação de ensaios, entrevistas universitárias, e habilidades de estudo avançadas.<br/> º Apoio contínuo através de aconselhamento de carreira, workshops de desenvolvimento de habilidades, e recursos para preparação de currículos e entrevistas.<br/> º Palestras, painéis de discussão e eventos que conectam alunos com profissionais de diversas áreas, proporcionando insights sobre carreiras e oportunidades futuras.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* End F.A.Q Section */}
    </div>
  )
}

export default Appsectionfaq