import React from 'react'

function Appsectionservices() {
  return (
    <div>
                {/* ======= Services Section ======= */}
                <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Cursos</h2>
              <p>O que oferecemos</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="icon-box" data-aos="zoom-in-left">
                  <div className="icon"><i className="bi bi-briefcase" style={{color: '#ff689b'}} /></div>
                  <h4 className="title"><a href>Enfermagem</a></h4>
                  <p className="description">O curso de Enfermagem que a instituição oferece, é uma formação abrangente e prática para preparar os alunos para atuar como profissionais competentes e compassivos na área da saúde.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={100}>
                  <div className="icon"><i className="bi bi-book" style={{color: '#e9bf06'}} /></div>
                  <h4 className="title"><a href>Análises Clínicas
                  </a></h4>
                  <p className="description">O curso de Análises Clínicas é projetado para fornecer aos alunos uma formação completa e prática na área de diagnóstico laboratorial. Este curso prepara os estudantes para atuar com competência e profissionalismo em ambientes de saúde e laboratórios clínicos.</p>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End Services Section */}
        
    </div>
  )
}

export default Appsectionservices