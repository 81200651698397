import React from "react";

const Appbanner = () => {
    return(
        <>
        {/* ======= Hero Section ======= */}
        <section id="hero" className="d-flex flex-column justify-content-end align-items-center">
        <div id="heroCarousel" data-bs-interval={5000} className="container carousel carousel-fade" data-bs-ride="carousel">
            {/* Slide 1 */}
            <div className="carousel-item active">
            <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">Bem-vindo ao <span>Bendizer</span></h2>
                <p className="animate__animated fanimate__adeInUp">Transforme Seu Futuro Conosco!,Programas Acadêmicos Inovadores, Infraestrutura de Ponta, Atividades Extracurriculares Envolventes, Apoio Personalizado para Seu Sucesso, Venha fazer parte da nossa comunidade!</p>
                <a href="/sobre" className="btn-get-started animate__animated animate__fadeInUp scrollto">Saiba Mais</a>
            </div>
            </div>
            {/* Slide 2 */}
            <div className="carousel-item">
            <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">🎓 Inscrições Abertas🎓</h2>
                <p className="animate__animated animate__fadeInUp">Educação de Excelência, Infraestrutura Moderna, Atividades Enriquecedoras: Clubes, esportes e eventos que ampliam suas habilidades e interesses, Apoio Individualizado. Não perca a chance de fazer parte de uma instituição que investe no seu futuro! Faça sua matrícula hoje e dê o primeiro passo para uma jornada educacional transformadora.</p>
                <a href="/precos" className="btn-get-started animate__animated animate__fadeInUp scrollto">Saiba Mais</a>
            </div>
            </div>
            {/* Slide 3 */}
            <div className="carousel-item">
            <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">Preparando os Líderes do Amanhã – Explore Nossas Ofertas Educacionais!</h2>
                <p className="animate__animated animate__fadeInUp"> Programas de mentoria onde líderes estabelecidos orientam os participantes mais jovens, oferecendo orientação personalizada e conselhos de carreira.</p>
                <a href="/sobre" className="btn-get-started animate__animated animate__fadeInUp scrollto">Saiba Mais</a>
            </div>
            </div>
            <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true" />
            </a>
            <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true" />
            </a>
        </div>
        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
            <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z">
            </path></defs>
            <g className="wave1">
            <use xlinkHref="#wave-path" x={50} y={3} fill="rgba(255,255,255, .1)">
            </use></g>
            <g className="wave2">
            <use xlinkHref="#wave-path" x={50} y={0} fill="rgba(255,255,255, .2)">
            </use></g>
            <g className="wave3">
            <use xlinkHref="#wave-path" x={50} y={9} fill="#fff">
            </use></g>
        </svg>
        </section>{/* End Hero */}


        </>
    )
};

export default Appbanner