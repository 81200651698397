import React from 'react'
import AppHeader from './Appheader';
import AppFooter from './Appfooter'

const Applayout = ({children}) => {
    return(
        <div className='layout'>
            <AppHeader />
        <div className='content'>
            {children}
        </div>
        <AppFooter />
    </div>
    )
};
export default Applayout
