import React from 'react'

function Appsectionfeatures() {
  return (
    <div>
                {/* ======= Features Section ======= */}
                <section id="features" className="features">
          <div className="container">
            <ul className="nav nav-tabs row d-flex">
              <li className="nav-item col-3" data-aos="zoom-in">
                <a className="nav-link active show" data-bs-toggle="tab" href="#tab-1">
                  <i className="ri-gps-line" />
                  <h4 className="d-none d-lg-block">Atividades de Projetos</h4>
                </a>
              </li>
              <li className="nav-item col-3" data-aos="zoom-in" data-aos-delay={100}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                  <i className="ri-body-scan-line" />
                  <h4 className="d-none d-lg-block">Laboratórios</h4>
                </a>
              </li>
              <li className="nav-item col-3" data-aos="zoom-in" data-aos-delay={200}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                  <i className="ri-sun-line" />
                  <h4 className="d-none d-lg-block">Atividades Esportivas</h4>
                </a>
              </li>
              <li className="nav-item col-3" data-aos="zoom-in" data-aos-delay={300}>
                <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                  <i className="ri-store-line" />
                  <h4 className="d-none d-lg-block">Eventos Culturais</h4>
                </a>
              </li>
            </ul>
            <div className="tab-content" data-aos="fade-up">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>Nossos queridos alunos têm uma abordagem prática e investigativa ao aprendizado, permitindo que os alunos explorem e integrem conhecimentos de várias disciplinas para criar algo significativo.</h3>
                    <p className="fst-italic">
                    Aqui está uma descrição detalhada de como essas atividades podem ser realizadas:
                    </p>
                    <ul>
                      <li><i className="ri-check-double-line" />Os alunos exploram um tema específico através de pesquisa e trabalho colaborativo.</li>
                      <li><i className="ri-check-double-line" /> Eles planejam, desenvolvem e apresentam um produto ou solução, integrando conhecimentos de várias disciplinas.</li>
                      <li><i className="ri-check-double-line" /> O processo envolve coleta e análise de dados, criação prática e apresentação dos resultados, seguido de reflexão e feedback. </li>
                    </ul>
                    <p>
                    Essas atividades promovem habilidades críticas, trabalho em equipe e aplicação prática do conhecimento.
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img 
                    src="assets/img/esc06.png" 
                    alt='Alunas' 
                    id='png_actividate'
                    style={{borderRadius:'50px',boxShadow:'0px 4px 8px #05411B'}} 
                    className="img-fluid" 
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>
                    Nos laboratórios, os alunos encontram um ambiente equipado com ferramentas e materiais específicos para realizar experimentos práticos nas áreas de enfermagem e análises clínicas. .</h3>
                    <p>
                    O espaço é geralmente equipado com microscópios, pipetas, béqueres, reagentes químicos, e dispositivos de medição, entre outros.
                    </p>
                    <p className="fst-italic">
                    Os laboratórios permitem que os alunos experimentem na prática conceitos teóricos estudados em sala de aula, ajudando a solidificar a compreensão e a aplicar o conhecimento de forma real e tangível.
                    </p>
                    <ul>
                      <li><i className="ri-check-double-line" /> Eles aplicam teorias aprendidas em sala de aula, utilizando equipamentos e materiais específicos para observar reações, testar hipóteses e coletar dados.</li>
                      <li><i className="ri-check-double-line" />Os laboratórios oferecem uma experiência hands-on que complementa a teoria, desenvolve habilidades de investigação e análise, e promove uma compreensão mais profunda dos conceitos científicos</li>
                      <li><i className="ri-check-double-line" /> Os alunos são orientados a seguir procedimentos de segurança e a registrar e interpretar seus resultados.</li>
                      <li><i className="ri-check-double-line" /> Os laboratórios proporcionam uma experiência prática indispensável para a formação completa dos alunos, preparando-os para futuros estudos e carreiras nas áreas científicas e tecnológicas.</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="assets/img/lab01.png" alt='Laboratório' className="img-fluid" 
                     style={{borderRadius:'50px',boxShadow:'0px 4px 8px #05411B'}} 
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-3">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>Atividades esportivas vai envolver a prática e o desenvolvimento de habilidades em diferentes esportes e atividades físicas, e a nossa instituição oferece modalidades como o fotebol, o andebol e o basquete.</h3>
                    <p>
                    Os alunos participam de aulas que ensinam técnicas e regras de diversos esportes, treinam regularmente para melhorar habilidades específicas e estratégias para competições, a escola pode participar de campeonatos e torneios interescolares, regionais e até nacionais.
                    </p>
                    <ul>
                      <li><i className="ri-check-double-line" /> Além de competir em campeonatos internos e externos, essas atividades promovem a saúde, o bem-estar e o trabalho em equipe, oferecendo uma experiência divertida e inclusiva para todos..</li>
                      <li><i className="ri-check-double-line" /> Proporcionam momentos de diversão e reforçam hábitos saudáveis.</li>
                      <li><i className="ri-check-double-line" /> Atividades esportivas promovem a cooperação, o trabalho em equipe e o espírito esportivo.</li>
                    </ul>
                    <p className="fst-italic">
                    Essas atividades são essenciais para o desenvolvimento físico, social e emocional dos alunos, promovendo habilidades importantes e um estilo de vida equilibrado.
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="assets/img/Ativi_Desport01.png" alt='Actividade Despostivas' style={{borderRadius:'50px',boxShadow:'0px 4px 8px #05411B'}}className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-4">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>Nossos estudantes são envolvidos na exploração e expressão das artes e da cultura de diversas formas.</h3>
                    <p>
                    Os alunos participam de oficinas e aulas de teatro, dança, música e artes visuais, desenvolvendo habilidades criativas e técnicas.
                    </p>
                    <p className="fst-italic">
                    Os alunos realizam apresentações de teatro, música e dança, mostrando seus talentos e práticas.
                    </p>
                    <ul>
                      <li><i className="ri-check-double-line" /> USessões com artistas, músicos e especialistas culturais que oferecem insights e experiências práticas.
                      </li>
                      <li><i className="ri-check-double-line" /> Passeios a museus, teatros e eventos culturais externos que enriquecem o aprendizado e a apreciação cultural..</li>
                      <li><i className="ri-check-double-line" />Essas atividades enriquecem a formação dos alunos, promovendo a criatividade, a expressão cultural e a apreciação das artes.</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="assets/img/Dia_Arica07.png" alt='actividades Culturais' className="img-fluid"  style={{borderRadius:'50px',boxShadow:'0px 4px 8px #05411B'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End Features Section */}
    </div>
  )
}

export default Appsectionfeatures