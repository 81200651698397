import React, { useEffect } from 'react';
import $ from 'jquery'; // Importar jQuery


function Appsectionportfolio() {

  useEffect(() => {
    $(window).on('load', () => {
      const portfolioFilters = $('#portfolio-flters li');
        portfolioFilters.on('click', function(e) {
          e.preventDefault();
          portfolioFilters.removeClass('filter-active');
          $(this).addClass('filter-active');
        });
    });
    // Cleanup function para remover eventos e instâncias quando o componente for desmontado
    return () => {
      $(window).off('load');
      $('#portfolio-flters li').off('click');
    };
  }, []);
  return (
    <div>
      {/* ======= Portfolio Section ======= */}
        <section id="portfolio" className="portfolio">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Notícias</h2>
              <p>O que fizemos</p>
            </div>
            <ul id="portfolio-flters" className="d-flex justify-content-end" data-aos="fade-up">
              <li data-filter="*" className="filter-active">Todos</li>
              <li data-filter=".filter-app">Destaques</li>
              <li data-filter=".filter-card">Notícias</li>
              <li data-filter=".filter-web">Eventos</li>
            </ul>
            <div className="row portfolio-container" data-aos="fade-up">
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-img"><img src="assets/img/prem03.jpg" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4> Alunos da instituição</h4>
                  <p>Destaques</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Nossa conquista"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-img"><img src="assets/img/Ativi_Desport02.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Estudantes qualificados</h4>
                  <p>Eventos</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Atividade desportiva"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-img"><img src="assets/img/Dia_Arica04.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Dia da África</h4>
                  <p>Destaques</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Atividade cultural"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-img"><img src="assets/img/Activi_ortog.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Cerimónia de entrega de certificados</h4>
                  <p>Notícias</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Atividade escolar"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-img"><img src="assets/img/Dia_Arica06.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Atividade Cultural</h4>
                  <p>Eventos</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Atividade cultural"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-img"><img src="assets/img/Ativi_Desport05.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Prémio da actividade Despostiva</h4>
                  <p>Destaques</p>
                  <a href=" # " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Atividade desportiva"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-img"><img src="assets/img/Activi_feira01.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>2ª Feira Educacional </h4>
                  <p>Notícias</p>
                  <a href="# " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Feira da educação"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-img"><img src="assets/img/Activi_feira02.png" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Exposição de plantas medicinais  </h4>
                  <p>Notícias</p>
                  <a href=" # " data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Feira da planta"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-img"><img src="assets/img/esc03.jpg" className="img-fluid" alt='' /></div>
                <div className="portfolio-info">
                  <h4>Nossa Instituição</h4>
                  <p>Eventos</p>
                  <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title=" Nossa instituição"></a>
                  <a href="/destaques" className="details-link" title="Mais Detalhes"><i className="bx bx-link" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Section */}
    </div>
  )
}
export default Appsectionportfolio