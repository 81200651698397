/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'

function Appsectioncontact() {
  return (
    <div>
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Contato</h2>
              <p>Contate-nos</p>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4" data-aos="fade-right">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt" />
                    <h4>Localização:</h4>
                    <p>Estamos localizados na Vila-Alice M. Rangel 
                    Rua. Fernando Pessoa Trav. Alberto Correia </p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>secretaria@itpsbendizer.co.ao</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone" />
                    <h4>Números de atendimento:</h4>
                    <p> 949748612, 945380685</p>
                    <p> 949748594, 938326467</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="Teu Nome" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Teu Email" required />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Assunto" required />
                  </div>
                  <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows={5} placeholder="Mensagem" required defaultValue={""} />
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">A sua mensagem foi enviada. Obrigado!</div>
                  </div>
                  <div className="text-center"><button type="submit">Enviar mensagem</button></div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
    </div>
  )
}

export default Appsectioncontact