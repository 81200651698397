import React from 'react'
import AppSectionAbout from '../components/section/Appsectionabout'
import AppSectionFeatures from '../components/section/Appsectionfeatures'
import AppSectionCTA from '../components/section/Appsectioncta'
import AppSectionServices from '../components/section/Appsectionservices'
import AppSectionPortfolio from '../components/section/Appsectionportfolio'
import AppSectionTestimonials from '../components/section/Appsectiontestimonials'
import AppSectionPricing from '../components/section/Appsectionpricing'
import AppSectionFAQ from '../components/section/Appsectionfaq'
import AppSectionTeam from '../components/section/Appsectionteam'
import AppSectionContact from '../components/section/Appsectioncontact'
import Applayout from '../components/Layout/Applayout'

function Apphome() {
  return (
    <div>
      <Applayout>
      <main id="main">
        <AppSectionAbout />
        <AppSectionFeatures />
        <AppSectionCTA />
        <AppSectionServices />
        <AppSectionPortfolio />
        <AppSectionTestimonials />
        <AppSectionPricing />
        <AppSectionFAQ />
        <AppSectionTeam />
        <AppSectionContact />
        </main>
      </Applayout>
    </div>
  )
}

export default Apphome