/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Apphome'
import About from './pages/Appabout'
import Precarios from './pages/Appprecarios'
import Destaques from './pages/Appdestaques'

function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/sobre' element={<About />} />
        <Route path='/preços' element={<Precarios />} />
        <Route path='/destaques' element={<Destaques />} />
        </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
