import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'

function Appabout() {
  return (
    <div>
      <Appheaderpage/>
        <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Quem somos</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Sobre nós</li>
          </ol>
        </div>
      </div>
    </section>{/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Nossa História</h2>
              <p>Percurso Histórico</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Instituto Técnico Privado Bendizer, foi fundado em 1998 em plena guerra civil após eleitorais isto é depois das primeiras eleições de Angola. Nesta altura Angola estava ensaiar o regime de economia de mercado abandonando a economia planificada cujo actor é o Estado. <br />
                É a primeira fase da reconstrução Nacional com greves no sistema do ensino público e os outros actores do ramo eram bem vindos. Foi nesta altura que foi fundada esta Instituição vocacionada no ensino em saúde.
                </p>
                <ul>
                  <li><i className="ri-check-double-line" /> O Instituto já formou mais de 5 000 profissionais dos quais vamos destacar 4 deles. </li>
                  <li><i className="ri-check-double-line" /> Temos quadro no mercado de trabalho graduado e pós-graduado.</li>
                  <li><i className="ri-check-double-line" /> Valorizar e promover a diversidade de ideias, culturas e perspectivas, criando um ambiente inclusivo para todos os estudantes e professores.</li>
                </ul>
                <div className="portfolio-description">
              <h2>Percurso Histórico de Formação de Quadros </h2>
              <p>
              O Instituto já formou mais de 5 000 profissionais dos quais vamos destacar 4 deles. 
              </p>
              <p>
              <strong>José Gonçalves Sebastião:</strong> Finalista do ano de 2002 curso de enfermagem, actualmente administrador do Centro de Saúde do Beiral. 
              </p>
              <p>
              <strong>Basílio Costa:</strong> finalista do ano de 2004 do curso de enfermagem, professor de química no ano de 2008 e subdirector pedagógica de 2013 e 2014 actualmente médico da clinica Multiferfil 
              </p>
              <p>
              <strong>Madalena Nengaga: </strong> finalista do ano de 2004 do curso de enfermagem, actualmente medica da maternidade Lucrécia Paim com um cargo de chefia.
              </p>
              <p>
              <strong>Fábio José:</strong> finalista de 2013 curso de ciências económicas e jurídicas, actualmente subgerente de uma da agencia de BIC.
              </p>
            </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                Numa pesquisa feita em 2019 apurou-se um nível aceitável dos estudantes da nossa Instituição e nos hospitais onde trabalham tem tendências de ocuparem cargo de chefia, este ano fomos informados que no concurso público do Ministério da Saúde 117 candidatos apurados são finalistas do nosso Instituto.  
                </p>
                <div className="portfolio-description">
              <h2>Promoções de quadros que terminaram os seus estudos </h2>
              <p>
              Desde 1999 temos finalistas com interrupção de três anos de suspensão dos curso de saúde. Temos quadro no mercado de trabalho graduado e pós-graduado.
              </p>
              <p>
              Em 1998 tinha um Centro para aulas práticas com o nome de Pecuca de FEIGES localizado na Hoje-Ya-Henda, os estudantes depois de alguns anos de aulas teóricas e práticas eram obrigados a exercitar no Centro.
              <br /> <br />
              <div className="member-img">
                    <img style={{borderRadius:'20px'}}  src="assets/img/esc04.png" className="img-fluid" alt='' />
                  </div>
              </p>
            </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4">
            <div className="portfolio-info">
              <h3>Promotores</h3>
              <ul>
                <li><strong><i className="ri-check-double-line" /></strong> Estanislau Barros </li>
                <li><strong><i className="ri-check-double-line" /></strong> Isabel Songo Barros</li>
              </ul>
              <h3>Gestores Directores </h3>
              <ul>
                <li><strong>1º</strong>: Rev. Estanislau Barros, 1998 a 2000</li>
                <li><strong>2º</strong>: Sr. Tomás, de 2000 a 2004 </li>
                <li><strong>3º</strong>: Dona Laura Dias, 2004 à Julho do mesmo ano.</li>
                <li><strong>4º</strong>: Drª Paulina Mayomona, de 2005 até a presente data.</li>
              </ul>
            </div>
            <div className="portfolio-description">
              <h2>Processos de desenvolvimento da Instituição</h2>
              <p>
              No ano de 2003 através de um decreto executivo conjunto n.º 6/02 encerra os Instituto Médio de Saúde no país, isto levou-nos a abrir outros cursos de Informática e Gestão de Contabilidade, prosseguindo assim apois  o Ministério da Educação Abrir o Puniv, Informática e Gestão de Contabilidade.<br /><br />Neste período o Instituto deu inicio a outro projecto de uma universidade no município da Samba bairro Benfica.<br /><br />
              No ano de 2008 o Instituto começou a passar por situações financeiras bastante défices que obrigou-nos a paralisar as actividades no final de 2009. <br /><br />
              No ano de 2011 reabrimos com os cursos de Ciências Físicas e Biológica e Económicas e Jurídicas com 23 alunos 16 salas de aulas, em 2012 recebemos a autorização para reabertura dos cursos de saúde de Enfermagem e Analises Clinicas contando assim com 212 alunos.<br /><br />
              Com a maturidade da Instituição e a consequente experiência alcançada no desenvolvimento desse trabalho o Instituto conta agora com 2.320 alunos, 23 salas de aulas, 1 anfiteatro, 5 laboratórios, 9 gabinetes, 11 wc. 

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
  </main>
  <Appfooter/>
    
    </div>
  )
}

export default Appabout