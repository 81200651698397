import React from 'react'

function Appsectiontestimonials() {
  return (
    <div>
            {/* ======= Testimonials Section ======= */}
            <section id="testimonials" className="testimonials">
        <div className="container">
        <div className="section-title" data-aos="zoom-out">
            <h2>Testemunhos</h2>
            <p>O que eles estão dizendo sobre nós</p>
        </div>
        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay={100}>
            <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="testimonial-item">
                <p>
                    <i style={{color:'#AB9458'}} className="bx bxs-quote-alt-left quote-icon-left" />
                    O Bendizer não é apenas um lugar para aprender, é um ambiente onde eu me sinto valorizado e inspirado a alcançar o meu melhor. Os professores são dedicados e realmente se importam com o nosso sucesso. Cada dia na escola é uma nova oportunidade para crescer e explorar.
                    <i style={{color:'#AB9458'}} className="bx bxs-quote-alt-right quote-icon-right" />
                </p>
                <img src="assets/img/est04.png" className="testimonial-img" alt='aluna' />
                <h3>Maura Paulo</h3>
                <h4>Esudante</h4>
                </div>
            </div>{/* End testimonial item */}
            </div>
            <div className="swiper-pagination" />
        </div>
        </div>
    </section>{/* End Testimonials Section */}
    </div>
  )
}

export default Appsectiontestimonials