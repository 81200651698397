import React, { useEffect } from 'react';
import $ from 'jquery'




const Appfooter = () => {

    useEffect(() => {
        // Código para alterar o texto do elemento
        const backtotop = $('.back-to-top');

        // Define a função para alternar a classe
        const toggleBacktotop = () => {
          if ($(window).scrollTop() > 100) {
            backtotop.addClass('active');
          } else {
            backtotop.removeClass('active');
          }}

          $(window).on('load', toggleBacktotop);
          $(document).on('scroll', toggleBacktotop);
      
          // Função de limpeza para remover ouvintes de eventos quando o componente for desmontado
          return () => {
            $(window).off('load', toggleBacktotop);
            $(document).off('scroll', toggleBacktotop);
          };
      }, []);
    return(
        <>
        {/* ======= Footer ======= */}
        <footer id="footer">
        <div className="container">
            <h3>Instituto Técnico Privado de Saúde Bendizer</h3>
            <p>Educando Hoje para o Mundo de Amanhã</p>
            <div className="social-links">
            <a href="https://www.facebook.com/ITPSBendizer" className="facebook"><i className="bx bxl-facebook" /></a>
            <a href="# " className="instagram"><i className="bx bxl-instagram" /></a>
            </div>
            <div className="copyright">
            © Copyright <strong><span>Bendizer</span></strong> Todos os direitos reservados.
            </div>
            <a href="# " class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
        </div>
        </footer>
        {/* End Footer */}
        </>
    )
};
export default Appfooter
