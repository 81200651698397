import React from 'react'

function Appsectionabout() {
  return (
    <div>
        {/* ======= About Section ======= */}
          <section id="about" className="about">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Sobre Nós</h2>
              <p>Quem somos</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Instituto Técnico Privado Bendizer, foi fundado em 1998 em plena guerra civil após eleitorais isto é depois das primeiras eleições de Angola. Nesta altura Angola estava ensaiar o regime de economia de mercado abandonando a economia planificada cujo actor é o Estado. É a primeira fase da reconstrução Nacional com greves no sistema do ensino público e os outros actores do ramo eram bem vindos. Foi nesta altura que foi fundada esta Instituição vocacionada no ensino em saúde.
                </p>
                <ul>
                  <li><i className="ri-check-double-line" /> O Instituto já formou mais de 5 000 profissionais dos quais vamos destacar 4 deles. </li>
                  <li><i className="ri-check-double-line" /> Temos quadro no mercado de trabalho graduado e pós-graduado.</li>
                  <li><i className="ri-check-double-line" /> Valorizar e promover a diversidade de ideias, culturas e perspectivas, criando um ambiente inclusivo para todos os estudantes e professores.</li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                Numa pesquisa feita em 2019 apurou-se um nível aceitável dos estudantes da nossa Instituição e nos hospitais onde trabalham tem tendências de ocuparem cargo de chefia, este ano fomos informados que no concurso público do Ministério da Saúde 117 candidatos apurados são finalistas do nosso Instituto.  
                </p>
                <a href="/sobre" className="btn-learn-more">Saber Mais</a>
              </div>
            </div>
          </div>
        </section>{/* End About Section */}
    </div>
  )
}

export default Appsectionabout