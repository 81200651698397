import React from 'react'
import Appheaderpage from '../components/Layout/Appheaderpage'
import Appfooter from '../components/Layout/Appfooter'


function Appdestaques() {
  return (
    <div>
         <Appheaderpage/>
         <main id="main">
      <section id="breadcrumbs" className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Destaques</h2>
          <ol>
            <li><a style={{color:'#AB9458'}} href="/">Home</a></li>
            <li>Destaques</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Breadcrumbs */}
    {/* ======= Portfolio Details Section ======= */}
    <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
          <div className="container">
            <div className="section-title" data-aos="zoom-out">
              <h2>Melhores Destaques</h2>
              <p>Nossos Destaques Autênticos</p>
            </div>
            <div className="row content" data-aos="fade-up">
              <div className="col-lg-6">
                <p>
                Bem-vindo à nossa Tabela de Preços! Aqui, você encontrará todas as informações detalhadas sobre os custos associados aos nossos serviços e produtos educacionais. A tabela está organizada para oferecer uma visão clara e acessível dos valores referentes a matrículas, mensalidades, materiais didáticos e outras taxas. <br />
                </p>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2> Alunos da Instituição</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/prem03.jpg" className="img-fluid" alt='Prémio Empreendedor' />
                </div>
                <br/>
                <strong>Destaques</strong>
                <p>Data: 01/08/2024 </p>
                <p>
                Caros Organizadores, membros a Unigest e representantes do Ministério da Indústria e comércio, Ministério da cultura e turismo.<br />
                </p>
                <p>
                Agradecemos à Unigest pelo compromisso contínuo em promover o empreendedorismo e à sua equipa pela organização impecável deste evento. Um agradecimento especial ao Ministério da Indústria e Comércio pelo apoio crucial e por acreditar non potencial transformador do empreendedorismo social. 
                <br/>
                </p>
                <p>
                Com uma visão voltada para o futuro, esses estudantes são agentes de mudança, prontos para contribuir significativamente em suas áreas de atuação. Este prêmio reconhece não apenas suas conquistas, mas também seu potencial para se tornarem líderes inovadores.
                </p>
                </div>
                </div>
                </div>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Dia da África</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/Dia_Arica04.png" className="img-fluid" alt='analises clinicas' />
                </div>
                <br/>
                <strong>Destaques </strong>
                <p>Data: 25/05/2019</p>
                <p>
                No dia 20 de maio de 2019, nossa instituição realizou uma vibrante celebração do Dia da África, uma data importante para reconhecer e promover a rica diversidade cultural e histórica do continente africano. <br /><br />O evento teve como objetivo não apenas homenagear a contribuição significativa da África para o mundo, mas também proporcionar um ambiente de aprendizado e reflexão sobre temas relevantes relacionados ao continente.<br />
                </p>
                <br/>
                </div>
                </div>
                </div>
                <div className="col-lg-12">
                <div className="portfolio-description">
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Prémio da actividade Despostiva</h2>
                <div className="member-img">
                        <img style={{borderRadius:'20px'}}  src="assets/img/Ativi_Desport05.png" className="img-fluid" alt='analises clinicas' />
                </div>
                <br/>
                <strong>Destaques</strong>
                <p>Data: 12/07/2020 </p>
                <p>
                É com grande entusiasmo que damos início à cerimônia de entrega de certificados do nosso Campeonato Interescolar de Futebol. Hoje, celebramos não apenas as vitórias e conquistas de nossos times, mas também o espírito esportivo, a camaradagem e o esforço dedicado que marcaram este campeonato.
                </p>
                <p>
                No último Torneio de Atletismo Escolar realizado no campo de esportes da escola, a turma do 3º ano do Ensino Médio destacou-se notavelmente e saiu-se vencedora. A competição reuniu várias turmas do ensino médio para uma série de provas de atletismo, com o objetivo de promover a saúde, o espírito esportivo e a coesão entre os alunos.
                </p>
                <br/>
                <br/>
                </div>
                </div>
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <div className="portfolio-description">
                <p>
                Estamos felizes em apresentar nossos cursos de Ensino Médio com preços fixos que oferecem excelente custo-benefício. Veja por que nossa instituição é a melhor escolha para você:  
                </p>
                <br/>
                <br/>
                <br/>
                <div style={{borderRadius:'22px'}} className="portfolio-info">
                <h2>Cerimónia de entrega de certificados</h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_ortog.png" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Data: 23/11/2019</p>
                <p>
                É com grande alegria que damos início à nossa cerimônia de entrega de certificados. Hoje, celebramos o esforço, a dedicação e a conquista de nossos alunos, cujas jornadas acadêmicas culminam neste momento especial. Esta cerimônia não é apenas uma formalidade, mas uma celebração do compromisso e da perseverança de cada um.<br />
                </p>
                <p>
                Neste momento, é importante reconhecer também o trabalho árduo dos nossos professores e funcionários. Sem o seu apoio, orientação e dedicação, nossas conquistas não seriam possíveis. Agradecemos profundamente a cada um de vocês por contribuir para a formação de nossos alunos e por criar um ambiente de aprendizado tão enriquecedor.
                </p>
                <br/>
                <br/>
                </div>
                <div style={{borderRadius:'22px',marginTop:'22px'}} className="portfolio-info">
                <h2>2ª Feira Educacional </h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_feira01.png" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Data: 11/06/2019 </p>
                <p>
                A 2ª Feira e Educacional Bendizer foi realizados em vinte e cinco de Outubro de dois mil e vinte sobe o lema: Promoção da Saúde como pilares para o desenvolvimento humano, foram realizadas varias actividades como:
                </p>
                <p>
                Efetuar medidas de Glicenia; Pressão Arterial; Peso e Estatura Orietar Dieta Saúdavel na idade Pré-Escolar e Escolar; Orientar as medidas de prevenção de doenças efecçõezas, de Transmição sexual
                </p>
                <p>
                Nesta feira, vimos projetos inovadores, pesquisas inspiradoras e apresentações que mostram o talento e o trabalho duro de nossos alunos. Cada um dos certificados que serão entregues hoje representa mais do que uma conquista acadêmica – é um reflexo do esforço e da dedicação.
                </p>
                <br />
                </div>
                <div style={{borderRadius:'22px',marginTop:'32px'}} className="portfolio-info">
                <h2>Exposição de plantas medicinais</h2>
                <div className="member-img">
                      <img style={{borderRadius:'20px'}}  src="assets/img/Activi_feira02.png" className="img-fluid" alt='Enfermagem' />
                </div>
                <br/>
                <strong>Notícias </strong>
                <p>Data: 26/04/2020</p>
                <p>
                Exposição de plantas medicinais realizadas pelos estudantes do Instituto Tecnico Privado Bendizer, apresentado como forma de tratar e prevenir doenças.
                </p>
                <p>
                Esta exposição foi uma oportunidade incrível para explorar a riqueza das plantas medicinais e seus usos tradicionais e contemporâneos. Cada um dos projetos e apresentações aqui exibidos não só demonstra um profundo entendimento sobre a biodiversidade e os benefícios das plantas, mas também a paixão e o cuidado com que foram preparados.
                </p>
                <p>
                Enquanto celebramos as conquistas de hoje, é importante lembrar que o estudo das plantas medicinais é um campo que continua a evoluir e oferecer novas descobertas.<br /><br /> Encorajo todos a continuar explorando, pesquisando e compartilhando seu entusiasmo por esse tema. O conhecimento adquirido aqui é um passo importante, mas a jornada de aprendizado começando.
                <p>
                    <br />
                </p>
                </p>
                <br/>
                <br/>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-lg-4">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <br />
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Estudantes Qualificados</h2>
            <div className="member-img">
                <img style={{borderRadius:'20px'}}  src="assets/img/Ativi_Desport02.png" className="img-fluid" alt='analises clinicas' />
            </div>
            <br/>
                <strong>Eventos</strong>
                <p>Data: 10/08/2019 </p>
                <p>
                Os estudantes qualificados de nossa instituição são indivíduos comprometidos com a excelência acadêmica e o desenvolvimento pessoal. <br /><br />Eles se destacam por sua curiosidade intelectual, capacidade de pensamento crítico e habilidade de trabalhar em equipe. Com uma mentalidade proativa, buscam constantemente novos desafios e oportunidades de aprendizado.<br />
                </p>
                <p>
                Nossos alunos demonstram responsabilidade social e ética, participando ativamente de projetos comunitários e iniciativas que promovem a inclusão e a sustentabilidade. <br/><br/>
                </p>
            <br/>
            </div>
            </div>
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Atividade Cultural</h2>
            <div className="member-img">
                    <img style={{borderRadius:'20px'}}  src="assets/img/Dia_Arica06.png" className="img-fluid" alt='analises clinicas' />
            </div>
            <br/>
                <strong>Eventos</strong>
                <p>Data: 05/04/2019 </p>
            <p>
            A abertura da atividade foi marcada por uma apresentação especial no auditório da escola. A diretora, Sra. Mariana Costa, fez um discurso caloroso destacando a importância da cultura e das artes na formação dos alunos.<br /><br /> Em seguida, os alunos da turma de Teatro apresentaram uma peça inspirada em histórias folclóricas locais, que encantou a plateia com sua energia e talento.
            </p>
            <p>
            Ao longo do dia, diversos estandes foram montados no pátio da escola, cada um representando uma expressão cultural diferente. Havia uma área dedicada à música, onde os alunos mostraram suas habilidades em diversos instrumentos.
            </p>
            <br/>
            </div>
            </div>
            <div className="portfolio-description">
            <div style={{borderRadius:'22px'}} className="portfolio-info">
            <h2>Nossa Instituição</h2>
            <div className="member-img">
                    <img style={{borderRadius:'20px'}}  src="assets/img/schooll.jpg" className="img-fluid" alt='analises clinicas' />
            </div>
            <br/>
                <strong>Eventos </strong>
                <p>Data: 11/03/2023</p>
            <p>
            Somos instituição educacional comprometida com a excelência acadêmica e o desenvolvimento integral dos nossos alunos. Localizada na Vila-Alice M. Rangel 
            Rua. Fernando Pessoa Trav. Alberto Correia, nossa escola é um centro vibrante de aprendizado e crescimento, dedicado a preparar nossos alunos para os desafios e oportunidades do futuro.
            </p>
            <h4>Visão e Valores</h4>
            <p>
            Ser reconhecida como uma escola inovadora e inclusiva, que inspira e capacita cada aluno a alcançar seu pleno potencial.
            </p>
            <h4>Compromisso com a Comunidade</h4>
            <p>
            Estamos profundamente comprometidos com a nossa comunidade local. Acreditamos na importância de criar parcerias com pais, empresas e organizações para enriquecer a experiência educacional dos nossos alunos. 
            <br />
            Realizamos eventos comunitários, projetos de serviço social.
            </p>
            <br/>
            <br/>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>{/* End Portfolio Details Section */}
    </main>
    <Appfooter/>
    </div>
  )
}

export default Appdestaques